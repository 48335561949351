<template>
    <div class="main-container">
        <!-- SECTION LOGOS -->
        <section class="section-logos">
            <h1 class="title-solo">Sponsors</h1>
            <div class="col-title">
                <p>
                    <strong>Interested in Becoming an Exclusive Sponsor? </strong><br /><br />
         Becoming a CryptoVirtua Exclusive Sponsor will give you special visibility benefits outside of the regular exhibitor package.<br />
         Contact us today to find out more about becoming an Exclusive Sponsor.
                </p>
                
            </div>

        </section>
        <!-- SECTION BECOME -->
        <section class="section-become">
            <h2>Become a Sponsor</h2>
            <div class="become-container">
                <h3>Take the opportunity to insert your logo and your website URL (when applicable) on:</h3>
                <ul>
                    <li>The 3D Exhibition Hall billboards</li>
                    <li>Our communication and promotional tools (emailings, social network posts, Motion Design promotional videos)</li>
                    <li>The Sponsors animated banner (visible from the home page of our virtual event when it will be opened and available)</li>
                    <li>The homepage of this event website</li>
                    <li>Here on the Sponsors page</li>
                </ul>
                <p class="become-baseline">
                    Become a sponsor of this event for <strong>$10,000</strong>
                </p>
            </div>
      
        </section>

        <!-- SECTION CONTACT -->
        <section class="section-contact">
            <h2>Contact Form<br /><span class="title-focus">to become a sponsor</span> </h2>
            <ContactForm></ContactForm>
        </section>
    </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
    name : 'Sponsors',
    metaInfo: {
        title: 'Be a Sponsor in an Online Tradeshow ',
        meta: [
            { name: 'description', content: 'Expose your brand to physicians, scientists, and industry experts in the US and around the world. Contact us to be a sponsor on the Ortho Digital Symposium.' },
        ]
    },
    components : { ContactForm },
    data() {
        return {
            sponsors : [
                {
                img : require('@/assets/sponsors/synthes3d.png'),
                alt : 'Logo SYNTHES3D',
                url : 'https://www.synthes3d.com/en/'
                },
                // {
                // img : require('@/assets/sponsors/OTW.png'),
                // alt : 'Logo OTW',
                // url : 'https://ryortho.com/'
                // },
                {
                img : require('@/assets/sponsors/logo-medtech.png'),
                alt : 'Logo MedTech',
                url : 'https://medtechmomentum.com/'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .section-logos {
        background-image: url('./../assets/bg-home-participate.png');
        background-repeat: no-repeat;
        background-position: top right;
        padding-bottom:80px;
    }

    .col-title p {
        color:$text-color;
        text-align: center;
        font-size:18px;
        line-height: 1.5em;
        width: 1000px;
        max-width: 90%;
        margin: 0 auto;
    }

    .col-title p strong {
        font-size:25px;
    }

    .col-title ul {
        color:$text-color;
        width: 1000px;
        max-width: 90%;
        margin: 0 auto;
        padding:0;
    }

    .col-title ul li {
        list-style: none;
        font-size:18px;
        line-height: 1.5em;
    }

    .section-become {
        padding:80px 0;
        background-color: $main-color;
        position: relative;
    }

    .become-sep-top {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-top.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .become-sep-bottom {
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-bottom-grey.png');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .become-baseline {
        color:$secondary-color;
        text-align: center;
        margin:0 20px;
        padding-top: 20px;
        font-size:25px;
    }

    .become-container {
        max-width: 1100px;
        width:calc(90% - 80px);
        padding:40px;
        margin:40px auto;
        border:1px solid #fff;
        border-radius:10px;
    }

    .become-container h3 {
        color:#fff;
        text-align: center;
        font-size:25px;
        font-weight:400;
    }

    .become-container ul {
        margin:0;
        padding:0;
    }

    .become-container ul li {
        color:#fff;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        margin-right: 20px;
    }

    .become-container ul li::before {
        content:'';
        width:25px;
        height:22px;
        background-image: url('./../assets/puce-blue.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }



    .section-contact {
        padding:80px 0;
        background-image: url('./../assets/bg-contact.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media screen and (max-width:768px) {
        .section-logos{
            padding-bottom:0;
        }

        .become-container h3 {
            font-size:20px;
        }

        .section-become {
            padding:40px 0;
            margin-top: 40px;
        }

        
        .become-container ul li {
            font-size: 18px;
            left:20px;
        }

        .become-baseline {
            font-size:20px;
        }

        .section-contact {
            padding:40px 0;
        }

    }
</style>